<template>
  <StaticPage>
    <el-card class="form_wrapper">
      <h2>{{ $t('loginClient.header') }}</h2>
      <div class="default_from_box"></div>
      <div class="from_box">
        <el-form :model="loginForm" ref="loginForm" :rules="loginFormRules">
          <el-row type="flex" justify="space-between">
            <el-col :span="10">
              <Autocomplete
                v-model="loginForm.countrycode_login"
                name="countrycode_login"
                :codes="codes"
                :label="$t('common.field.countryCode')"
                @select="handleSelect"
              >
              </Autocomplete>
            </el-col>
            <el-col :span="13">
              <InputTemplate
                v-model.trim="loginForm.mobile_login"
                name="mobile_login"
                :label="$t('common.field.phone')"
              >
              </InputTemplate>
            </el-col>
          </el-row>
          <InputTemplate
            v-model.trim="loginForm.password_login"
            name="password_login"
            show-password
            :label="$t('common.field.pw')"
            @keyup.enter.native="loginSubmit"
          >
          </InputTemplate>

          <el-button @click.native="loginSubmit">
            {{ $t('common.button.login') }}
          </el-button>
          <p class="forgotPass">
            <router-link to="/forgetPasswordMobile">{{ $t('common.button.forgotPw') }}</router-link>
          </p>
        </el-form>
      </div>
    </el-card>
  </StaticPage>
</template>

<script>
import { md5, rsa } from '@/util/encrypt';
import { apiGetUserLocation } from '@/resource';
import helper from '@/util/signinHelper';
import Autocomplete from '@/components/form/Autocomplete';
import StaticPage from '@/components/template/staticPage/StaticPageDefault';
import countryCodeEn from '@/assets/data/countrycode_en.json';
import countryCodeCn from '@/assets/data/countrycode_cn.json';
import { validateNumber } from '@/util/validation';

export default {
  name: 'LoginClientByMobile',
  components: { StaticPage, Autocomplete },
  props: {
    mobileNumber: {
      type: String,
      default: ''
    },
    ctCode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      userCountry: '',
      countryCodeEnJson: countryCodeEn,
      countryCodeCnJson: countryCodeCn,
      codes: [],
      loginForm: {
        countrycode_login: this.ctCode ? this.ctCode : '',
        mobile_login: this.mobileNumber ? this.mobileNumber : '',
        password_login: ''
      },
      loginFormRules: {
        countrycode_login: [
          {
            required: true,
            trigger: 'blur',
            validator: validateNumber
          }
        ],
        mobile_login: [
          {
            required: true,
            trigger: 'blur',
            validator: validateNumber
          }
        ],
        password_login: [
          {
            required: true,
            message: this.$t('common.formValidation.pwReq'),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  methods: {
    handleSelect(item) {
      this.loginForm.countrycode_login = item.code;
    },
    loginSubmit() {
      this.$refs['loginForm'].validate(valid => {
        if (valid) this.login();
      });
    },
    login() {
      const countrycode_login = this.loginForm.countrycode_login;
      const mobile_login = rsa(this.loginForm.mobile_login);
      const password_login = md5(this.loginForm.password_login);
      helper.signInMobile(countrycode_login, mobile_login, password_login);
    },
    getLoc() {
      let country = '';
      let countryCode;
      apiGetUserLocation().then(resp => {
        let res = resp.data.split(/\n/g).filter(line => line.trim());
        for (const element of res) {
          if (element.slice(0, 3) === 'loc') {
            country = element.slice(4);
            let filteredCountry = this.countryCodeEnJson.filter(function(p) {
              return p.iso_country_code === country.toUpperCase();
            });
            countryCode = filteredCountry[0].code;
          }
        }
        this.userCountry = country;
        this.loginForm.countrycode_login = countryCode;
      });
    },
    getCode() {
      if (this.lang === 'zh_CN') {
        this.codes = this.countryCodeCnJson;
      } else {
        this.codes = this.countryCodeEnJson;
      }
    }
  },
  mounted() {
    setTimeout(function() {
      $('.default_from_box')
        .css('animation', 'none')
        .css('display', 'none');
      $('.from_box').css('display', 'block');
    }, 1000);

    this.getCode();
    this.getLoc();
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/loginClient/login.scss';
</style>
